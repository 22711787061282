import { useState } from 'react';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

import { Form, FormikProvider, useFormik } from 'formik';
import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/styles';
import { 
    Box, 
    Button, 
    Container, 
    Typography,
    Stack,
    Alert,
    TextField,     
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// routes
import { PATH_AUTH } from '../../routes/paths';
// components
import Page from '../../components/Page';
//
import { SentIcon } from '../../assets';
// hooks
import useIsMountedRef from '../../hooks/useIsMountedRef';
// API
import { ForgotPassword } from '../../Api/Auth'; 
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function ForgotPasswordPage() {
  const [email, setEmail] = useState('');
  const [sent, setSent] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required')
  });

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: ForgotPasswordSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        let resp = await ForgotPassword(values);
        
        if(resp.status === 200){
          setSent(true);
          setEmail(formik.values.email);
        }
        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error) {
        enqueueSnackbar(error.response.data.message, {variant: 'error'});
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.message });
          setSubmitting(false);
        }
      }
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <RootStyle title="Forgot Password">
      <Container>
        <Box sx={{ maxWidth: 480, mx: 'auto' }}>
          {!sent ? (
            <>
              <Typography variant="h3" paragraph>
                Forgot your password?
              </Typography>
              <Typography sx={{ color: 'text.secondary', mb: 5 }}>
                Please enter the email address associated with your account and We will email you a link to reset your
                password.
              </Typography>

                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <Stack spacing={3}>
                        {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

                        <TextField
                            fullWidth
                            {...getFieldProps('email')}
                            onKeyUp={(e)=>{(e.target.value).trim()}}
                            type="email"
                            label="Email address"
                            error={Boolean(touched.email && errors.email)}
                            helperText={touched.email && errors.email}
                        />

                        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
                            Send Reset Password Link
                        </LoadingButton>
                        </Stack>
                    </Form>
                </FormikProvider>

              <Button fullWidth size="large" 
              component={RouterLink} 
              to={PATH_AUTH.login} 
              sx={{ mt: 1 }}>
                Back to Login
              </Button>
            </>
          ) : (
            <Box sx={{ textAlign: 'center' }}>
              <SentIcon sx={{ mb: 5, mx: 'auto', height: 160 }} />

              <Typography variant="h3" gutterBottom>
                Request sent successfully
              </Typography>
              <Typography>
                We have sent a confirmation email to &nbsp;
                <strong>{email}</strong>
                <br />
                Please check your email.
              </Typography>

              <Button size="large" variant="contained" 
              component={RouterLink} 
              to={PATH_AUTH.login} 
              sx={{ mt: 5 }}>
                Back to Login
              </Button>
            </Box>
          )}
        </Box>
      </Container>
    </RootStyle>
  );
}
