import React from 'react';
import {Provider} from 'react-redux';

import store from './Store/Store';

// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
// components
// import Settings from './components/settings';
// import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import NotistackProvider from './components/NotistackProvider';

// ----------------------------------------------------------------------

export default function App() {
  store.subscribe(() => {
    localStorage.setItem('reduxStore', JSON.stringify(store.getState()));
   
  })

  return (
    <Provider store={store}>
      <ThemeConfig>
        <ThemePrimaryColor>
          <NotistackProvider>
              <ScrollToTop />
              <Router />            
          </NotistackProvider>
        </ThemePrimaryColor>
      </ThemeConfig>

    </Provider>
  );
}
