import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    // Dashboard Routes
    {
      path: 'app',
      element: <DashboardLayout />,
      children: [
        { path: 'dashboard', element: <DashBoardScreen /> },
        
        { path: 'institute/add', element: <InstituteAddScreen /> },
        { path: 'institute/edit/:id', element: <InstituteEditScreen /> },
        { path: 'institute/list', element: <InstituteListScreen /> },
        { path: 'institute/view/:id', element: <InstituteViewScreen /> },
        
        { path: 'group/add', element: <GroupAddScreen /> },
        { path: 'group/list', element: <GroupListScreen /> },
        { path: 'group/view/:id', element: <GroupViewScreen /> },
        
        { path: 'member/add', element: <MemberAddScreen /> },
        { path: 'member/list', element: <MemberListScreen /> },
        { path: 'member/view/:id', element: <MemberViewScreen /> },
        
        { path: '*', element: <Navigate to="/app/dashboard" replace /> }
      ]
    },    
    { path: '*', element: <Navigate to="/app/dashboard" replace /> }
  ]);
}

// IMPORT COMPONENTS

// 
const DashBoardScreen = Loadable(lazy(() => import('../screens/dashboard')));
const InstituteAddScreen = Loadable(lazy(() => import('../screens/institute/Add')));
const InstituteEditScreen = Loadable(lazy(() => import('../screens/institute/Edit')));
const InstituteListScreen = Loadable(lazy(() => import('../screens/institute/List')));
const InstituteViewScreen = Loadable(lazy(() => import('../screens/institute/View')));

const GroupAddScreen = Loadable(lazy(() => import('../screens/group/Add')));
const GroupListScreen = Loadable(lazy(() => import('../screens/group/List')));
const GroupViewScreen = Loadable(lazy(() => import('../screens/group/Detail')));

const MemberAddScreen = Loadable(lazy(() => import('../screens/member/Add')));
const MemberListScreen = Loadable(lazy(() => import('../screens/member/List')));
const MemberViewScreen = Loadable(lazy(() => import('../screens/member/Detail')));
