// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_MAIN = '/app';
const ROOTS_AUTH = '/auth';

// ----------------------------------------------------------------------

export const PATH_DASHBOARD = {
  root: ROOTS_MAIN,
  dashboard: path(ROOTS_MAIN, '/dashboard'),
  institute: {
    add: path(ROOTS_MAIN, '/institute/add'),
    edit: path(ROOTS_MAIN, '/institute/edit'),
    list: path(ROOTS_MAIN, '/institute/list'),
    view: path(ROOTS_MAIN, '/institute/view'),
  },
  group: {
    add: path(ROOTS_MAIN, '/group/add'),
    view: path(ROOTS_MAIN, '/group/view'),
    list: path(ROOTS_MAIN, '/group/list'),
  },
  member: {
    add: path(ROOTS_MAIN, '/member/add'),
    view: path(ROOTS_MAIN, '/member/view'),
    list: path(ROOTS_MAIN, '/member/list'),
  },
  
};


export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  forgotPassword: path(ROOTS_AUTH, '/forgot-password'),
  
};