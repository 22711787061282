import axios from 'axios';
import { loginFailure, loginSuccess } from '../Actions/ActionCreators';
import { BaseURL } from '../config/BaseURL';

export const Login = (values) =>{
    return (dispatch) =>{
        axios.post(BaseURL+"/superAdminAccess/login", values).then((res)=>{              
            let temp = {
                user:res.data.data,
                token:res.headers.authorization
            }         
            dispatch(loginSuccess(temp))
        }).catch((err)=>{
            if (!err.response) {
                alert("Something Went wrong : Please Check Network or Server Connectivity")
            }
            else {
                dispatch(loginFailure(err.response.data));

            }
        })
    } 
}

export const ForgotPassword = (data) =>{
    return axios.post(BaseURL+"/superAdminAccess/forgotPassword", data)
}

export const ResetPassword = (data) =>{
    return axios.post(BaseURL+"/superAdminAccess/resetpassword", data)
}