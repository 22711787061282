import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
// Routes
import { useNavigate, useParams } from 'react-router-dom';
import { PATH_AUTH } from '../../routes/paths'
// Icon
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
// material
import { styled } from '@mui/styles';
import { 
    Box, 
    Container, 
    Typography,
    Stack,
    Alert,
    TextField,     
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// API
import { ResetPassword } from '../../Api/Auth';
// components
import Page from '../../components/Page';
import { MIconButton } from '../../components/@material-extend';

// hooks
import useIsMountedRef from '../../hooks/useIsMountedRef';
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function ResetPasswordPage() {
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const param = useParams();
  const navigate = useNavigate();
  const ForgotPasswordSchema = Yup.object().shape({
    new_pass: Yup.string().required('New password is required')
    .min(6,"Password must contain at-least 6 characters"),
    confirm_pass: Yup.string().required('Please confirm your new password.')
    .min(6,"Password must contain at-least 6 characters")
    .oneOf([Yup.ref('new_pass'), null], 'Passwords must match'),
  });

  const formik = useFormik({
    initialValues: {
      token:param.token,
      new_pass: '',
      confirm_pass: ''
    },
    validationSchema: ForgotPasswordSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {      
      try {        
        const sendData = {
          token_id:values.token,
          password:values.new_pass
        }
        let resp = await ResetPassword(sendData);        
        if(resp.status === 200){
          enqueueSnackbar(resp.data.message, {
            variant: 'success',
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            )          
          });
          navigate(PATH_AUTH.login)
        }
        if (isMountedRef.current) {
          setSubmitting(true);
        }
      } catch (error) {        
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.message });
          setSubmitting(false);
        }
      }
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <RootStyle title="Reset Password">
      <Container>
        <Box sx={{ maxWidth: 480, mx: 'auto' }}>
          <Typography variant="h5" paragraph>
            Reset your password?
          </Typography>          
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}
                <TextField
                    fullWidth
                    {...getFieldProps('new_pass')}
                    autoComplete={"false"}
                    type="password"
                    label="New Password"
                    error={Boolean(touched.new_pass && errors.new_pass)}
                    helperText={touched.new_pass && errors.new_pass}
                />
                <TextField
                    fullWidth
                    {...getFieldProps('confirm_pass')}
                    autoComplete="false"
                    type="password"
                    label="Confirm Password"
                    error={Boolean(touched.confirm_pass && errors.confirm_pass)}
                    helperText={touched.confirm_pass && errors.confirm_pass}
                />
                

                <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
                    Reset Password
                </LoadingButton>
                </Stack>
            </Form>
          </FormikProvider>

        </Box>
      </Container>
    </RootStyle>
  );
}
