// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';
import GroupIcon from '@mui/icons-material/Group';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  user: getIcon('ic_user'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard')
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'Dashboard', path: PATH_DASHBOARD.dashboard, icon: ICONS.dashboard },

    ]
  },
  {
      subheader: '',
      items: [
        { title: 'Institutes', path: PATH_DASHBOARD.institute.list, icon: <AccountBalanceIcon /> },
        { title: 'Groups', path: PATH_DASHBOARD.group.list, icon: <GroupIcon /> },
        { title: 'Members', path: PATH_DASHBOARD.member.list, icon: ICONS.user },

      ]
    },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  
];

export default sidebarConfig;
